import { Expose } from "class-transformer";

export class UpdateChecklistDto {
  @Expose()
  id: string;

  @Expose()
  includeIncidentSection: boolean;

  @Expose()
  includeDocumentationSection: boolean;

  @Expose()
  includeEquipmentSection: boolean;

  @Expose()
  includeObservationSection: boolean;

  @Expose()
  isActive: boolean;
}
