import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { QuestionAnswerType } from "@altertec_gparn/lib";
import { Type } from "class-transformer";
import { AnswerOptionDto } from "@/core/features/answer-option/answer-option.dto";

export class QuestionDto extends BaseEntityDto {
  title: string;

  subtitle?: string;

  answerType: QuestionAnswerType;

  checklistId?: string;

  generatesIssue?: boolean;

  @Type(() => AnswerOptionDto)
  answerOptions: AnswerOptionDto[];

  order?: number;

  // Subquestion
  @Type(() => AnswerOptionDto)
  relatedAnswer?: AnswerOptionDto;
  relatedAnswerId?: string;

  toString(): string {
    return `${this.title}`;
  }

  isFreeAnswer() {
    return this.answerType === QuestionAnswerType.FREE_ANSWER;
  }

  isDropdown() {
    return this.answerType === QuestionAnswerType.DROPDOWN;
  }
}
