import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { VehicleIssueDto } from "@/core/features/vehicle-issues/vehicle-issue.dto";
import { UpdateVehicleIssueDto } from "@/core/features/vehicle-issues/update-vehicle-issue.dto";
import { CreateVehicleIssueDto } from "@/core/features/vehicle-issues/create-vehicle-issue.dto";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { VehicleIssueStatusEnum } from "@altertec_gparn/lib";
import { UpdateVehicleIssueStatusDto } from "@/core/features/vehicle-issues/update-vehicle-issue-status.dto";

export class VehicleIssuesResource extends ResourceBaseService<
  VehicleIssueDto,
  CreateVehicleIssueDto,
  UpdateVehicleIssueDto
> {
  protected EntityDto = VehicleIssueDto;
  protected CreateEntityDto = CreateVehicleIssueDto;
  protected UpdateEntityDto = UpdateVehicleIssueDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/vehicle-issues");
  }

  async getNumberOfPendingIssues(): Promise<number> {
    return (await this.findAll({ filters: { status: VehicleIssueStatusEnum.PENDING }, limit: 1 })).total;
  }

  changeStatus(id: string, status: VehicleIssueStatusEnum): Promise<VehicleIssueDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/change-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateVehicleIssueStatusDto, { status: status, id: id }),
      })
    );
  }
}
