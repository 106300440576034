import { Transform, Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { ChecklistDto } from "@/core/features/checklists/checklist.dto";
import { AnswerDto } from "@/core/features/answers/answer.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { VehicleDto } from "@/core/features/vehicles/vehicle.dto";
import { UserDto } from "@/core/features/users/user.dto";

export class VehicleHasChecklistDto extends BaseEntityDto {
  @Type(() => ChecklistDto)
  checklist: ChecklistDto;
  checklistId: string;

  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  @Type(() => AnswerDto)
  answers: AnswerDto[];

  observations?: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  @Type(() => UserDto)
  createdBy: UserDto;

  getQuestionAnswers() {
    if (!this.answers) return [];
    return this.answers.filter((answer) => !!answer.questionId);
  }

  getDocumentationAnswers() {
    if (!this.answers) return [];
    return this.answers.filter((answer) => !!answer.documentationId);
  }

  getEquipmentAnswers() {
    if (!this.answers) return [];
    return this.answers.filter((answer) => !!answer.equipmentId);
  }
}
