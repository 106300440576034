import { Expose, Type } from "class-transformer";
import { CreateFileDto } from "../file/create-file.dto";

export class CreateReferenceDocumentDto {
  @Expose()
  documentationTypeId: string;

  @Type(() => CreateFileDto)
  @Expose()
  file: CreateFileDto;

  @Expose({ name: "modelIds" })
  models: string[];

  @Expose({ name: "elementIds" })
  elements: string[];

  @Expose({ name: "interventionIds" })
  interventions: string[];

  @Expose({ name: "clientGroupIds" })
  clientGroups: string[];

  @Expose({ name: "workplaceIds" })
  workplaces: string[];
}
