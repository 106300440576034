import { NavigationFailure, RouteLocationNormalized, Router as VueRouter, RouteRecordRaw } from "vue-router";
import { default as vueRouter } from "../../../router";
import { AuthStoreService } from "../security/auth-store.service";
import { UsersResource } from "@/core/features/users/users.resource";
import { get } from "lodash";
import { UserDto } from "@/core/features/users/user.dto";
import { SecurityManager } from "@/core/common/security/security.manager";
import { UnauthorizedError } from "@/core/common/http/errors/unauthorized.error";
import { PostsResource } from "@/core/features/posts/posts.resource";

export class Router {
  private readonly router: VueRouter;

  constructor(
    private readonly authStoreService: AuthStoreService,
    private readonly securityManager: SecurityManager,
    private readonly usersResource: UsersResource,
    private readonly postsResource: PostsResource
  ) {
    this.router = vueRouter;
    this.checkIfRequiresAuth();
  }

  push(url: string): Promise<void | NavigationFailure> {
    return this.router.push(url);
  }

  replace(url: string): Promise<void | NavigationFailure> {
    return this.router.replace(url);
  }

  getInstance(): VueRouter {
    return this.router;
  }

  private checkIfRequiresAuth() {
    this.router.beforeEach(async (to) => {
      if (to.meta?.requiresAuth) {
        if (!(await this.isLogged())) {
          new Promise(() => {
            throw new UnauthorizedError("No tienes permisos para visitar esta página", to.fullPath, to);
          });
        } else {
          const me = await this.getMe();
          const redirection = await this.checkIfRoleHasPermissions(to, me);
          if (redirection) return redirection;
          if (await this.hasUnreadedPosts(to, me)) {
            return {
              path: "/my-space/posts",
              query: { unreadWarning: "true" },
            };
          }
        }
      }
    });
  }

  private async hasUnreadedPosts(to: RouteLocationNormalized, me: UserDto) {
    if (to.path.includes("posts") || me.username === "autotest") return 0;
    try {
      const unreadedPost = await this.postsResource.getUnread();
      return unreadedPost > 0;
    } catch {
      return false;
    }
  }

  private async getMe(): Promise<UserDto> {
    try {
      return await this.usersResource.getMe();
    } catch (e) {
      return null;
    }
  }

  private async checkIfRoleHasPermissions(to: RouteLocationNormalized, me?: UserDto) {
    if (!me) {
      return {
        path: "/login",
        query: { redirects: to.meta.requiresAuth ? to.fullPath : undefined },
      };
    }
    if (!this.securityManager.hasPermission(get(to, "meta.permissions", []), me)) {
      return {
        path: "/403",
      };
    }
    if (to.meta?.sectionAccess) {
      if (!this.securityManager.hasSectionAccess(to.meta.sectionAccess, me)) {
        return {
          path: "/403",
        };
      }

      if (to.meta?.redirects) {
        const redirection = this.getFirstAllowedRoute(to, me);
        if (redirection) return redirection;
      }
    }
  }

  private async getFirstAllowedRoute(to: RouteLocationNormalized, me: UserDto): Promise<RouteRecordRaw> {
    const parent = to.matched.find((route) => route.meta && route.meta.redirects && to.fullPath === route.path);
    if (!parent) return null;
    return parent.children.find(
      (route) => route.meta?.permissions && this.securityManager.hasPermission(route.meta.permissions, me)
    );
  }

  private async isLogged(): Promise<boolean> {
    return this.authStoreService.hasToken();
  }
}
