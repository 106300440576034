import { Expose, Transform, Type } from "class-transformer";
import { parseAppDate } from "@/utils/dates/dateFormats";
import { CreateAnswerDto } from "@/core/features/answers/create-answer.dto";

export class CreateVehicleHasChecklistDto {
  @Expose()
  checklistId: string;

  @Expose()
  vehicleId: string;

  @Expose()
  @Type(() => CreateAnswerDto)
  answers?: CreateAnswerDto[];

  @Expose()
  observations?: string;

  @Transform(({ value }) => parseAppDate(value, false))
  @Expose()
  date: Date;
}
