import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { HttpRequest } from "@/core/common/http/http-request";
import { plainToInstance } from "class-transformer";
import { WarehouseIssueStatusEnum } from "@altertec_gparn/lib";
import { WarehouseIssueDto } from "@/core/features/warehouse-issues/warehouse-issue.dto";
import { CreateWarehouseIssueDto } from "@/core/features/warehouse-issues/create-warehouse-issue.dto";
import { UpdateWarehouseIssueDto } from "@/core/features/warehouse-issues/update-warehouse-issue.dto";
import { UpdateWarehouseIssueStatusDto } from "@/core/features/warehouse-issues/update-warehouse-issue-status.dto";
import { WarehouseIssueRecordDto } from "@/core/features/warehouse-issues/warehouse-issue-record.dto";

export class WarehouseIssuesResource extends ResourceBaseService<
  WarehouseIssueDto,
  CreateWarehouseIssueDto,
  UpdateWarehouseIssueDto
> {
  protected EntityDto = WarehouseIssueDto;
  protected CreateEntityDto = CreateWarehouseIssueDto;
  protected UpdateEntityDto = UpdateWarehouseIssueDto;
  protected RecordEntityDto = WarehouseIssueRecordDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/warehouse-issues");
  }

  async getNumberOfPendingIssues(): Promise<number> {
    return (await this.findAll({ filters: { status: WarehouseIssueStatusEnum.PENDING }, limit: 1 })).total;
  }

  changeStatus(id: string, status: WarehouseIssueStatusEnum): Promise<WarehouseIssueDto> {
    return this.apiHttpClient.request(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/change-status",
        method: "PATCH",
        auth: true,
        body: plainToInstance(UpdateWarehouseIssueStatusDto, { status: status, id: id }),
      })
    );
  }
}
