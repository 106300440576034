import { VehicleDto } from "../vehicles/vehicle.dto";
import { Transform, Type } from "class-transformer";
import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { parseApiDate } from "@/utils/dates/dateFormats";
import { VehicleIssueStatusEnum } from "@altertec_gparn/lib";
import { UserDto } from "@/core/features/users/user.dto";
import { AnswerDto } from "@/core/features/answers/answer.dto";

export class VehicleIssueDto extends BaseEntityDto {
  @Type(() => VehicleDto)
  vehicle: VehicleDto;
  vehicleId: string;

  description: string;

  @Type(() => AnswerDto)
  relatedAnswer: AnswerDto;
  relatedAnswerId: string;

  @Transform(({ value }) => parseApiDate(value))
  date: Date;

  @Type(() => UserDto)
  createdBy: UserDto;

  status: VehicleIssueStatusEnum;
}
