import { BaseEntityDto } from "@/core/common/entities/base-entity.dto";
import { Type } from "class-transformer";
import { QuestionDto } from "@/core/features/questions/question.dto";
import { VehicleHasChecklistDto } from "@/core/features/vehicles-have-checklists/vehicle-has-checklist.dto";
import { AnswerOptionDto } from "@/core/features/answer-option/answer-option.dto";
import { ItemDto } from "@/core/features/items/item.dto";
import { VehicleHasDocumentDto } from "@/core/features/vehicles-have-documents/vehicle-has-document.dto";
import { VehicleIssueDto } from "@/core/features/vehicle-issues/vehicle-issue.dto";

export class AnswerDto extends BaseEntityDto {
  @Type(() => QuestionDto)
  question?: QuestionDto;
  questionId?: string;

  @Type(() => AnswerOptionDto)
  selectedAnswer?: AnswerOptionDto;
  selectedAnswerId?: string;

  freeAnswer?: string;

  @Type(() => ItemDto)
  equipment?: ItemDto;
  equipmentId?: string;

  @Type(() => VehicleIssueDto)
  issue?: VehicleIssueDto;
  issueId?: string;

  isMissing?: boolean;

  @Type(() => VehicleHasDocumentDto)
  documentation?: VehicleHasDocumentDto;
  documentationId?: string;

  @Type(() => VehicleHasChecklistDto)
  vehicleHasChecklist?: VehicleHasChecklistDto;
  vehicleHasChecklistId?: string;
}
