import { ResourceBaseService } from "@/core/common/resource-base/resource-base.service";
import { ApiHttpClient } from "@/core/common/http/api-http-client";
import { ChecklistDto } from "@/core/features/checklists/checklist.dto";
import { UpdateChecklistDto } from "@/core/features/checklists/update-checklist.dto";
import { UpdateQuestionOrderDto } from "@/core/features/questions/update-question-order.dto";
import { plainToInstance } from "class-transformer";
import { HttpRequest } from "@/core/common/http/http-request";
import { ReviewPeriodEnum } from "@altertec_gparn/lib";
import { UpdateReviewPeriodDto } from "@/core/features/checklists/update-review-period.dto";

export class ChecklistsResource extends ResourceBaseService<ChecklistDto, null, UpdateChecklistDto> {
  protected EntityDto = ChecklistDto;
  protected UpdateEntityDto = UpdateChecklistDto;

  constructor(protected readonly apiHttpClient: ApiHttpClient) {
    super(apiHttpClient, "/checklists");
  }

  updateOrder(orderedQuestions: UpdateQuestionOrderDto[]): Promise<void> {
    const transformedOrderedQuestions = orderedQuestions.map((question) =>
      plainToInstance(UpdateQuestionOrderDto, question, { excludeExtraneousValues: true })
    );
    return this.apiHttpClient.request<UpdateQuestionOrderDto[], void>(
      HttpRequest.create({
        url: this.resourceUrl + "/update-order",
        method: "PATCH",
        auth: true,
        body: transformedOrderedQuestions,
      })
    );
  }

  updateReviewPeriod(id: string, reviewPeriod: ReviewPeriodEnum): Promise<void> {
    return this.apiHttpClient.request<UpdateReviewPeriodDto, void>(
      HttpRequest.create({
        url: this.resourceUrl + "/" + id + "/update-review-period",
        method: "PATCH",
        auth: true,
        body: { id, reviewPeriod } as UpdateReviewPeriodDto,
      })
    );
  }
}
